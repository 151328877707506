import { useState, useRef, useEffect } from 'react';

import * as adminUsersService from 'services/adminUsersService';
import * as userService from 'services/userService';
import { modalInitialState, modalType, popUpInitialState } from 'utils/initialModalValues';

import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Chip,
    ClickAwayListener,
    Divider,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';

import { IconSettings, IconLogout } from '@tabler/icons';
import ProfileModal from './ProfileModal';
import AlertModal from 'components/common/AlertModal';
import useNotification from 'hooks/useNotification';
import PublishSpinner from 'components/common/PublishSpinner';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    // const customization = useSelector((state) => state.customization);

    // const [sdm, setSdm] = useState(true);
    // const [value, setValue] = useState('');
    // const [notification, setNotification] = useState(false);
    // const [selectedIndex, setSelectedIndex] = useState(-1);
    const { showSuccess, showError } = useNotification();

    const { logout, user } = useAuth();
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(modalInitialState);
    const [popUpModal, setPopUpModal] = useState(popUpInitialState);
    const [closePopUp, setClosePopUp] = useState(false);
    const [userFromDb, setUserFromDb] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);

    const anchorRef = useRef(null);

    useEffect(() => {
        adminUsersService.getSingleUser()
            .then((result) => {
                setUserFromDb({ oldUsername: result.username, ...result });
            })
            .catch(err => console.log(err.message));
    }, [user]);

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const editHandler = () => {
        setModal({ show: true, type: modalType.edit });
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const openPopUp = () => {
        setPopUpModal({ show: true, type: modalType.add })
        setClosePopUp(true)
    };

    const closeModals = () => {
        closeModalHandler()
        setClosePopUp(false)
    };

    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const onDeletenButton = () => {
        console.log('delete')
    };

    const closeModalHandler = () => setModal(modalInitialState);

    const onSaveHandler = async (data) => {
        try {
            setShowSpinner(true);

            const { disabled, customClaims, emailVerified, metadata, providerData, tokensValidAfterTime, confirmed_password, ...user } = data;

            let res = {};

            if (user.username !== user.oldUsername) {
                const result = await userService.checkUsername(user.username);

                if (result) {
                    throw new Error("This username already exists!");
                }
            }

            res.username = user.username;
            res.gender = user.gender;
            res.avatar = user.avatar;
            res.email = user.email;
            res.country = user.cotuntry;

            if (user.name.length >= 1) {
                res.displayName = user.name;
            }

            if (user.password.length >= 1) {
                res.password = user.password;
            }

            const updatedUser = await adminUsersService.updateUser(res);

            updatedUser.oldUsername = updatedUser.username;

            setUserFromDb(state => state.uid === updatedUser.uid ? updatedUser : state);

            closeModalHandler();

            setShowSpinner(false);
            showSuccess('Successful Updated user');
        } catch (error) {
            setShowSpinner(false);
            showError(error.message);
        }
    };

    return (
        <>
            <PublishSpinner open={showSpinner} value={2800} showSpinner={showSpinner} />

            <ProfileModal
                userData={userFromDb}
                open={modal.show && [modalType.add, modalType.edit].includes(modal.type)}
                openPopUp={openPopUp}
                onSaveHandler={onSaveHandler}
                type={modal.type}
            />
            <AlertModal
                open={modal.show && modal.type === modalType.alert}
                openEdit={popUpModal.show && popUpModal.type === modalType.add}
                closeModal={closeModalHandler}
                closePopUp={closePopUp}
                onDeleteHandler={onDeletenButton}
                setClosePopUp={setClosePopUp}
                closeModals={closeModals}
                tableName="User"
            />
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={userFromDb?.avatar}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">Hello,</Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {userFromDb?.username || userFromDb?.email}
                                                </Typography>
                                            </Stack>
                                            <Stack direction='row' alignItems='left' mt={0.2} mb={0.4}>
                                                <Typography variant="body2">Project Admin</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction='row' alignItems='left'>
                                            <Button onClick={editHandler}><IconSettings style={{ marginRight: '0.3vw' }} /> User Usettings</Button>
                                        </Stack>
                                        <Divider />
                                        <Stack direction='row' alignItems='left'>
                                            <Button onClick={handleLogout}><IconLogout style={{ marginRight: '0.3vw' }} /> Logout</Button>
                                        </Stack>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
