import axiosServices from 'utils/axios';

export const getAllUsers = async (inputObj) => {
    try {
        const response = await axiosServices.get(`admin/list-users/`, {
            params: {
                limit: inputObj.rowsPerPage,
                order: inputObj.order,
                orderBy: inputObj.orderBy,
                page: inputObj.page
            }
        });

        return response.data
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const setAdmin = async (id, role) => {
    try {
        const body = {
            userId: id,
            role
        };
        
        const response = await axiosServices.post(`admin/makeAdmin`, body);

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const removeAdmin = async (id) => {
    try {
        const response = await axiosServices.delete(`admin/makeAdmin/${id}`);

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const getSearched = async (inputObj) => {
    try {
        const response = await axiosServices.post(`admin/user/search/`, inputObj);

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const createNewUser = async (uid, email, username) => {
    try {
        const response = await axiosServices.post(`users/createNewUser`, {
            uid,
            email,
            username
        });

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const checkUsername = async (username) => {
    try {
        const response = await axiosServices.post(`users/checkUsername`, { username });

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};