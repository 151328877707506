import { NOTIFICATION_OPEN } from '../actions/actionTypes';

const initialState = {
    action: false,
    open: false,
    message: 'Note archived',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
    },
    variant: 'default',
    alertSeverity: 'success',
    transition: 'Fade',
    close: true,
    actionButton: false
};

const notification = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_OPEN:
            return {
                ...state,
                action: !state.action,
                open: action.open ? action.open : initialState.open,
                anchorOrigin: action.anchorOrigin ? action.anchorOrigin : initialState.anchorOrigin,
                variant: action.variant ? action.variant : initialState.variant,
                alertSeverity: action.alertSeverity ? action.alertSeverity : initialState.alertSeverity,
                transition: action.transition ? action.transition : initialState.transition,
                close: action.close === false ? action.close : initialState.close,
                actionButton: action.actionButton ? action.actionButton : initialState.actionButton,
                message: action.message ? action.message : initialState.message
            };
        default:
            return state;
    }
};

export default notification;

export const getNotification = (state) => state;
