import axiosServices from 'utils/axios';

export const getAllUsers = async () => {
    try {
        const response = await fetch(`/api/admin-users`)
        const users = await response.json();

        return users;
    } catch (err) {
        throw new Error(`Can't take all users`);
    }
};

export const usersLength = async () => {
    try {
        const response = await fetch(`/api/admin-users/length`)

        const length = await response.json()
        return length;
    } catch (err) {
        throw new Error(`Can't take all users`);
    }
}

export const getSingleUser = async (userId) => {
    try {
        const response = await axiosServices.get(`admin/user/${userId}`);

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const updateUser = async (userData) => {
    try {
        const response = await axiosServices.put(`admin/user/update`, userData);

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};