export const modalType = {
    add: 'add',
    edit: 'edit',
    edit_hc: 'edit_home_card',
    details: 'details',
    alert: 'alert',
    duplicate: 'duplicate',
    stats: 'stats',
};

export const modalInitialState = {
    show: false,
    type: modalType.add,
};

export const popUpInitialState = {
    show: false,
    type: modalType.alert,
};

export const initialDescriptionsEventsArr = [
    { id: "1", title: "purchase_cancelled", }
]


export const initialDescriptionPusnhNotificationssArr = [
    { id: "1", title: "Campaign" },
    { id: "2", title: "Article" },
    { id: "3", title: "Link" },
    { id: "4", title: "Deeplink", },
    { id: "5", title: "Purchase_cancelled" },
    { id: "6", title: "Discount" },
    { id: "7", title: "version_update" },
    { id: "8", title: "GTC_update" },
    { id: "9", title: "Feedback" },
]




