import { Grid, Typography } from "@mui/material";
import React from "react";
import Environment from "utils/Environment";

const EnvironmentComponent = () => {
  return (
    <Grid ml={4}>
      <Typography
        variant="h1"
        color={Environment.color}
        textTransform="uppercase"
      >
        {Environment.name} admin
      </Typography>
    </Grid>
  );
};

export default EnvironmentComponent;
