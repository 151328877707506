import { Link } from 'react-router-dom';
import logoFeia from '../../../assets/feia.png'
import packageJson from '../../../../package.json'
// material-ui
import { ButtonBase, Typography, Grid } from '@mui/material';

// project imports
import config from 'config';
// import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    return (
        <Grid container textAlign='center' justifyContent="center" >
            <Grid>
                < ButtonBase disableRipple component={Link} to={config.defaultPath} >
                    {/* <Logo /> */}
                    < img src={logoFeia} alt="logo feia" style={{
                        maxWidth: '100%',
                        height: 'auto',
                        width: '50%',
                        marginBottom: '0.2rem'
                    }
                    } />

                </ButtonBase >
            </Grid>

            <Grid textAlign='center' alignItems='center'  >
                <Typography textAlign='center'>
                    admin  version: {packageJson.version}
                </Typography>
            </Grid>

        </Grid>

    )
};

export default LogoSection;
