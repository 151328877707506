import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

import firebase from "config/firebase";

import { LOGIN, LOGOUT } from "actions/actionTypes";
import accountReducer from "reducers/accountReducer";

import Loader from "ui-component/Loader";

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          user.getIdTokenResult().then((idTokenResult) => {
            if (
              idTokenResult.claims.role === "admin" ||
              idTokenResult.claims.admin === true
            ) {

              dispatch({
                type: LOGIN,
                payload: {
                  isLoggedIn: true,
                  user: {
                    id: user.uid,
                    email: user.email,
                    name: user.displayName || "Feia Admin User",
                  },
                },
              });

              sessionStorage.setItem('token', idTokenResult.token);
              sessionStorage.setItem('expirationTime', idTokenResult.expirationTime);
            } else {
              dispatch({
                type: LOGOUT,
              });
            }
          });
        } else {
          dispatch({
            type: LOGOUT,
          });

          sessionStorage.clear();
        }
      }),
    [dispatch]
  );

  const firebaseEmailPasswordSignIn = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseRegister = async (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

  const logout = () => firebase.auth().signOut();

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const updateProfile = () => { };
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => { },
        firebaseGoogleSignIn,
        logout,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default FirebaseContext;