import firebase from 'firebase/app';
import 'firebase/auth';
import "firebase/firestore";

import config from './index';
let app;

if (!firebase.apps.length) {
    app = firebase.initializeApp(config.firebase);
}

export default firebase;

export const firebaseApp = app;