import { useRef } from 'react';
import { modalType } from 'utils/initialModalValues';
import { gridSpacing } from "reducers/constant";

import { initialValues } from './ProfileModalConstants';
import { validateProfileModal } from './ProfileModalHelpers'

import { Formik, Form, Field } from "formik";

import {
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    TextField,
    DialogActions,
} from "@mui/material";

import SubCard from 'ui-component/cards/SubCard';
import ConfirmationButtons from 'components/common/ConfirmationButtons/ConfirmationButtons';
import './ProfileModal.scss'

const ProfileModal = ({
    userData,
    open,
    type,
    openPopUp,
    onSaveHandler,
}) => {
    const formRef = useRef();

    const submitHandler = (values) => onSaveHandler(values);

    return (
        <Dialog
            className="partners-form-modal"
            open={open}
            sx={{
                "&>div:nth-of-type(3)": {
                    "&>div": {
                        minWidth: '90%',
                    },
                },
            }}
        >
            <DialogTitle>
                {type === modalType.add ? "Add User Profile" : "Edit User Profile"}
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{ ...initialValues, ...userData }}
                    onSubmit={submitHandler}
                    innerRef={formRef}
                    validate={validateProfileModal}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <Grid container spacing={gridSpacing} sx={{ my: 0 }}>
                                <Grid item xs={12}  >
                                    <Grid container spacing={5} >
                                        <Grid item xs={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Field
                                                        as={TextField}
                                                        name="name"
                                                        type="text"
                                                        fullWidth
                                                        label="Name"
                                                        style={{ width: '30vw' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field
                                                        as={TextField}
                                                        name="username"
                                                        type="text"
                                                        fullWidth
                                                        label="Username"
                                                        style={{ width: '30vw' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SubCard title="Change Password">
                                                <Grid container spacing={gridSpacing} >
                                                    <Grid item xs={12}>
                                                        <Field
                                                            as={TextField}
                                                            name="password"
                                                            type="password"
                                                            fullWidth
                                                            label="New Password"
                                                            style={{ width: '30vw' }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <Field
                                                            as={TextField}
                                                            name="confirmed_password"
                                                            type="password"
                                                            fullWidth
                                                            label="Confirm password"
                                                            style={{ width: '30vw' }}
                                                        />
                                                        {errors.confirmed_password && touched.confirmed_password && (
                                                            <div className="field-error-message">{errors.confirmed_password}</div>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </SubCard>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
            <DialogActions>
                <ConfirmationButtons
                    openPopUp={openPopUp}
                    type={type}
                    handleSubmit={() => formRef.current.handleSubmit()}
                />
            </DialogActions>
        </Dialog>
    )
};

export default ProfileModal;