import { NOTIFICATION_OPEN } from './actionTypes';

export const showSuccessNotification = (message) => ({
    type: NOTIFICATION_OPEN,
    open: true,
    variant: 'alert',
    alertSeverity: 'success',
    message
});

export const showErrorNotification = (message) => ({
    type: NOTIFICATION_OPEN,
    open: true,
    variant: 'alert',
    alertSeverity: 'error',
    message
});

export const showWarningNotification = (message) => ({
    type: NOTIFICATION_OPEN,
    open: true,
    variant: 'alert',
    alertSeverity: 'warning',
    message
});

export const showSearchNotification = (message) => ({
    type: NOTIFICATION_OPEN,
    open: true,
    variant: 'alert',
    alertSeverity: 'info',
    message
});