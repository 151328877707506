// assets
import {
    IconDashboard,
    IconTypography,
    IconDeviceAnalytics,
    IconClipboardList,
    IconApps,
    IconUserCheck,
    IconNews,
    IconWorld,
    IconFile,
    IconReport,
    IconArchive,
    IconPhoto,
    IconFolders,
    IconBoxMultiple,
    IconNotebook,
    IconUsers,
    IconUserCircle,
    IconDeviceTablet,
    IconVocabulary,
    IconLayoutBottombar,
    IconMovie,
    IconFileCertificate,
    IconUserExclamation,
    IconShoppingCartDiscount,
    IconBrowser,
    IconTornado,
    IconAd,
    IconBrandCodesandbox,
    IconMessageCircle2,
    IconQuestionMark,
    IconListCheck,
    IconListDetails,
    IconTags,
    IconCalendarEvent,
    IconMoodKid,
    IconNotification
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconTypography,
    IconClipboardList,
    IconApps,
    IconUserCheck,
    IconNews,
    IconWorld,
    IconFile,
    IconReport,
    IconArchive,
    IconBoxMultiple,
    IconPhoto,
    IconFolders,
    IconNotebook,
    IconUsers,
    IconUserCircle,
    IconDeviceTablet,
    IconVocabulary,
    IconLayoutBottombar,
    IconMovie,
    IconFileCertificate,
    IconUserExclamation,
    IconShoppingCartDiscount,
    IconBrowser,
    IconTornado,
    IconAd,
    IconBrandCodesandbox,
    IconMessageCircle2,
    IconQuestionMark,
    IconListDetails,
    IconTags,
    IconCalendarEvent,
    IconMoodKid,
    IconNotification
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Feia Admin Panel Tables',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        // {
        //     id: 'analytics',
        //     title: <FormattedMessage id="analytics" />,
        //     type: 'item',
        //     url: '/dashboard/analytics',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // },
        {
            id: 'environments',
            title: 'Environments',
            type: 'item',
            url: '/environments',
            icon: icons.IconBrandCodesandbox,
            breadcrumbs: false
        },
        {
            id: 'categories',
            title: 'Categories',
            type: 'item',
            url: '/categories',
            icon: icons.IconApps,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        },
        // {
        //     id: 'admin-users',
        //     title: 'Admin Users',
        //     type: 'item',
        //     url: '/admin-users',
        //     icon: IconUserExclamation,
        //     breadcrumbs: false
        // },
        {
            id: 'isers-feedback',
            title: 'Users Feedback',
            type: 'collapse',
            icon: IconMessageCircle2,
            children: [
                {
                    id: 'users-feedback',
                    title: 'Users Feedback',
                    type: 'item',
                    url: '/users-feedback',
                    icon: IconMessageCircle2,
                    breadcrumbs: false
                },
                {
                    id: 'template-answers',
                    title: 'Template Answers',
                    type: 'item',
                    url: '/template-answers',
                    icon: IconListCheck,
                    breadcrumbs: false
                },
                {
                    id: 'template-themes',
                    title: 'Themes',
                    type: 'item',
                    url: '/template-themes',
                    icon: IconListDetails,
                    breadcrumbs: false
                },
                {
                    id: 'template-tags',
                    title: 'Tags',
                    type: 'item',
                    url: '/template-tags',
                    icon: IconTags,
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'articles',
            title: 'Articles',
            type: 'item',
            url: '/articles',
            icon: icons.IconNews,
            breadcrumbs: false

        },
        {
            id: 'translations',
            title: 'Translations',
            type: 'item',
            url: '/translations',
            icon: icons.IconWorld,
            breadcrumbs: false
        },
        {
            id: 'web-translations',
            title: 'Web Translations',
            type: 'item',
            url: '/web-translations',
            icon: icons.IconBrowser,
            breadcrumbs: false
        },
        {
            id: 'documents',
            title: 'Documents',
            type: 'item',
            url: '/documents',
            icon: icons.IconFile,
            breadcrumbs: false
        },
        {
            id: 'surveys',
            title: 'Surveys',
            type: 'item',
            url: '/surveys',
            icon: icons.IconReport,
            breadcrumbs: false
        },
        {
            id: 'template_notifications',
            title: 'Template Notification',
            type: 'item',
            url: '/template-notifications',
            icon: icons.IconNotification,
            breadcrumbs: false   
        },
        {
            id: 'push_notification',
            title: 'Push Notification',
            type: 'item',
            url: '/push-notification',
            icon: icons.IconFileCertificate,
            breadcrumbs: false
        },
        {
            id: 'events',
            title: 'Events',
            type: 'item',
            url: '/events',
            icon: icons.IconCalendarEvent,
            breadcrumbs: false
        },
        {
            id: 'baby_names',
            title: 'Baby names',
            type: 'item',
            url: '/baby-names',
            icon: icons.IconMoodKid,
            breadcrumbs: false
        },
        {
            id: 'ads',
            title: "Ads",
            type: 'collapse',
            icon: icons.IconAd,
            children: [
                {
                    id: 'partners',
                    title: `Partners`,
                    type: 'item',
                    url: '/partners',
                    icon: icons.IconFolders,
                    breadcrumbs: false
                },
                {
                    id: 'campaigns',
                    title: `Campaigns`,
                    type: 'item',
                    url: '/campaigns',
                    icon: icons.IconReport,
                    breadcrumbs: false
                },
                {
                    id: 'summary',
                    title: `Campaign Summary`,
                    type: 'item',
                    url: '/summary',
                    icon: icons.IconTornado,
                    breadcrumbs: false
                },
                {
                    id: 'items',
                    title: 'Items',
                    type: 'collapse',
                    icon: icons.IconReport,
                    children: [
                        {
                            id: 'promotions',
                            title: 'Promotions',
                            type: 'item',
                            url: '/promotions',
                            icon: icons.IconClipboardList,
                            breadcrumbs: false
                        },
                        {
                            id: 'discount-code',
                            title: 'Discount Codes',
                            type: 'item',
                            url: '/discount-code',
                            icon: icons.IconShoppingCartDiscount,
                            breadcrumbs: false
                        },
                        {
                            id: 'add-home-card',
                            title: `Ads Home Card`,
                            type: 'item',
                            url: '/add-home-card',
                            icon: icons.IconArchive,
                            breadcrumbs: false
                        },
                        {
                            id: 'ad-articles',
                            title: `Advert Articles`,
                            type: 'item',
                            url: '/ad-articles',
                            icon: icons.IconNews,
                            breadcrumbs: false
                        },
                        {
                            id: 'linked-words',
                            title: 'Linked Words',
                            type: 'item',
                            url: '/linked-words',
                            icon: icons.IconVocabulary,
                            breadcrumbs: false
                        },
                        {
                            id: 'splash-screen',
                            title: 'Splash Screen',
                            type: 'item',
                            url: '/splash-screen',
                            icon: icons.IconDeviceTablet,
                            breadcrumbs: false
                        },
                        {
                            id: 'action-buttons',
                            title: 'Action Buttons',
                            type: 'item',
                            url: '/action-buttons',
                            icon: icons.IconPhoto,
                            breadcrumbs: false
                        },
                        {
                            id: 'inline_banner',
                            title: 'Inline Banner',
                            type: 'item',
                            url: '/inline-banner',
                            icon: icons.IconLayoutBottombar,
                            breadcrumbs: false
                        },
                        {
                            id: 'rewarded-videos',
                            title: 'Rewarded Video',
                            type: 'item',
                            url: '/rewarded-videos',
                            icon: icons.IconMovie,
                            breadcrumbs: false
                        },

                        {
                            id: 'ads-brochure',
                            title: 'Ads Brochure',
                            type: 'item',
                            url: '/ads-brochure',
                            icon: icons.IconFileCertificate,
                            breadcrumbs: false
                        },
                        {
                            id: 'ads-surveys',
                            title: 'Ads Surveys',
                            type: 'item',
                            url: '/ads-surveys',
                            icon: icons.IconQuestionMark,
                            breadcrumbs: false
                        },
                        {
                            id: 'web-logos',
                            title: 'Website Logos',
                            type: 'item',
                            url: '/web-logos',
                            icon: icons.IconBrowser,
                            breadcrumbs: false
                        },
                    ]
                }
            ]
        },
        {
            id: 'home-cards',
            title: `Home Cards`,
            type: 'item',
            url: '/home-cards',
            icon: icons.IconBoxMultiple,
            breadcrumbs: false
        },
        {
            id: 'set-images-border',
            title: 'Set Images Border',
            type: 'item',
            url: '/set-images-border',
            icon: icons.IconPhoto,
            breadcrumbs: false
        },
        {
            id: 'community',
            title: "Community",
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'community-categories',
                    title: 'Categories',
                    type: 'item',
                    url: '/community-categories',
                    icon: icons.IconNotebook,
                    breadcrumbs: false
                },
                {
                    id: 'threads',
                    title: 'Threads',
                    type: 'item',
                    url: '/threads',
                    icon: icons.IconNews,
                    breadcrumbs: false
                },
                {
                    id: 'community-users',
                    title: 'Community Users',
                    type: 'item',
                    url: '/community-users',
                    icon: icons.IconUserCircle,
                    breadcrumbs: false
                }
            ]
        },
    ]
};

export default dashboard;
