import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import * as serviceWorker from 'serviceWorker';
import store, { persister } from './store';

import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import translation_en from "utils/locales/translation_en.json"
import translation_es from "utils/locales/translation_es.json"

import 'assets/scss/style.scss';
import config from 'config';

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
    en: {
      translation: translation_en               // 'common' is our custom namespace
    },
    es: {
      translation: translation_es               // 'common' is our custom namespace
    },
  },
});


ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <BrowserRouter basename={config.basename}>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
