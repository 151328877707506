import { createStore } from 'redux';
import { persistStore } from 'redux-persist';

import rootReducer from 'reducers';

const store = createStore(rootReducer);

export const persister = persistStore(store);

export default store;
