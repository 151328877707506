import React from 'react';
import { useSelector } from 'react-redux';
import './App.css';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from './routes';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Notification from 'components/common/Notification';

// auth provider
import { FirebaseProvider } from 'contexts/FirebaseContext';
// import { JWTProvider } from 'contexts/JWTContext';
// import { Auth0Provider } from 'contexts/Auth0Context';

function App() {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        {/* RTL layout */}
        {/* <RTLLayout> */}

        <Locales>
          <NavigationScroll>
            <FirebaseProvider>
              <>
                <Routes />
                <Notification />
              </>
            </FirebaseProvider>
          </NavigationScroll>
        </Locales>

        {/* </RTLLayout> */}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
